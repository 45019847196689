import { Component } from "react";
import Banner from "./Banner";

class Appointments extends Component {


  render() {
    return (
      <div className="appointments">
        <Banner text={"Terminbuchung"} />
        <div className="appointments-wrapper">
         <iframe src={"https://termin.kutschera.co.at/hackls/"}  title="Terminbuchung" />
        </div>
      </div>
    );
  }
}

export default Appointments;
