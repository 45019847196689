import { Component } from "react";

class Banner extends Component {
  render() {
    return (
      <div className="banner">
        <h1 className="roll-in">{this.props.text}</h1>
      </div>
    );
  }
}

export default Banner;
