import { Component } from "react";
import Banner from "./Banner";
import portrait from "../images/portrait2.png";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Banner text={"Herzlich Willkommen"} />

        <div className="home-wrapper">
          <div className="portrait-wrapper">
            <img className="fade-in" src={portrait} alt="Portraitbild von Dr. Sylvia Hackl" />
          </div>

          <div className="home-header">
            <h2 className="fade-in">Dr. Sylvia Hackl</h2>
            <div className="fade-in-1 supporting-text">
              Fachärztin für Haut- und Geschlechtskrankheiten
            </div>
          </div>

          <div className="home-information fade-in-2">
            <div className="wr">
              <div>
                <div className="info-wraper">
                  <p>Sehr geehrte Patientinnen und Patienten!</p>
                  <p>Ich freue mich, meine Kassenordination 2025 zu eröffnen.</p>

                  <ul className="info-wrapper">
                    <li>
                      <i className="fa fa-map-marker-alt" /> <b>Ort:</b> Feschnigstraße 46, 9020 Klagenfurt
                    </li>
                    <li>
                      <i className="fa fa-calendar" />  <b>Zeitpunkt:</b> Ender Jänner 2025
                    </li>
                  </ul>


                  <h3>Anmeldung:</h3>
                  <ul>
                    <li><b>Online:</b> ab sofort möglich.
                      <a href="/termin" className="button-link"
                        onClick={(e) => this.onNavigate(e, "termin")}>Zur Terminbuchung</a>
                    </li>
                    <li><b>Per E-Mail:</b> ab 2. Januarwoche</li>
                    <li><b>Per Telefon: </b> ab 2. Januarwoche</li>
                  </ul>

                  {/* <p>Termine können zur Zeit <b>nur</b> per Mail an <a href="mailto:praxis@derma-klagenfurt.at">praxis@derma-klagenfurt.at</a> entgegengenommen werden.</p> */}

                  <p>  Nähere Informationen folgen.</p>

                  <b> Bis dahin alles Gute und bleiben Sie gesund.</b>

                  <div class="highlighted-text"> Ihre Sylvia Hackl</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
