import { Component } from "react";
import Banner from "./Banner";

class DataPrivacy extends Component {
  render() {
    return (
      <div className="data-privacy">
        <Banner text={"Datenschutz"} />
        <div className="data-privacy-wrapper">
          <p>
            Der Schutz Ihrer persönlichen Daten ist uns als Verantwortlicher iSd
            DSGVO und des DSG ein besonderes Anliegen. Wir verarbeiten Ihre
            Daten daher ausschließlich auf Grundlage der gesetzlichen
            Bestimmungen (DSGVO, DSG, TKG 2003) und nach dessen Grundsätzen. In
            diesen Datenschutzinformationen informieren wir Sie über die
            wichtigsten Aspekte der Datenverarbeitung.
          </p>

          <h2>Personenbezogene Daten, die Sie uns bekanntgeben</h2>
          <p>
            Über diesen Webauftritt werden von uns keine personenbezogenen Daten
            erfasst.
          </p>
          <h2>Serverlogs</h2>
          <p>
            Jeder Ihrer Zugriffe auf die Websites wird in einer
            Protokolldatei (Serverlogs) für ein Monat mit folgenden Daten
            gespeichert:
          </p>
          <ul>
            <li>IP-Adresse</li>
            <li>Anfragedetails und Zieladresse</li>
            <li>Name der abgerufenen Datei und übertragene Datenmenge</li>
            <li>Datum und Uhrzeit des Abrufs</li>
            <li>Meldung, ob der Abruf erfolgreich war</li>
            <li>Meldung der gesendeten Bytes</li>
            <li>
              Meldung, ob die Seite über eine Drittseite oder direkt aufgerufen
              wurde
            </li>
            <li>Browserinformation</li>
          </ul>

          <p>
            Diese Daten dienen ausschließlich der Überprüfung der
            Systemsicherheit. Es erfolgt keine personenbezogene Auswertung oder
            Profilbildung.
          </p>

          <h2>Cookies</h2>
          <p>
            Cookies sind kleine Textdateien, die auf dem Computer der Anfragenden
            gespeichert werden. Die in den Cookies enthaltenen Informationen
            dienen der Sitzungssteuerung, der Lastverteilung der Server, zu
            Analysezwecken und zu der Erhöhung der Sicherheit unserer
            Anwendungen sowie der Anpassbarkeit und Bedienbarkeit. Es werden
            keine personenbezogenen Daten in den Cookies gespeichert.
          </p>

          <p>
            Sie können die Verwendung von Cookies durch eine entsprechende
            Einstellung Ihrer Browsersoftware verhindern. Wenn Sie keine Cookies
            zulassen, können Sie unsere Webauftritte trotzdem benutzen.
          </p>

          <h2>Nutzerprofile</h2>
          <p>Es werden keine personenbezogenen Nutzerprofile erstellt.</p>

          <h2>Minderjährigenschutz</h2>
          <p>
            Personen unter 14 Jahren sollten ohne Zustimmung der Eltern oder
            Erziehungsberechtigten keine personenbezogenen Daten an uns
            übermitteln. Wir fordern keine personenbezogenen Daten von Kindern
            und Jugendlichen an. Wissentlich sammeln wir solche Daten nicht und
            geben sie auch nicht an Dritte weiter.
          </p>

          <h2>Google Maps Datenschutzerklärung</h2>
          <p>
            Wir verwenden Google Maps der Firma Google Inc. (1600 Amphitheatre
            Parkway Mountain View, CA 94043, USA) auf unserer Webseite.
          </p>

          <p>
            Durch die Nutzung der Funktionen dieser Karte werden Daten an Google
            übertragen. Welche Daten von Google erfasst werden und wofür diese
            Daten verwendet werden, können Sie auf{" "}
            <a href="https://www.google.com/intl/de/policies/privacy/">
              https://www.google.com/intl/de/policies/privacy/
            </a>{" "}
            nachlesen.
          </p>

          <h2>Links zu Webseiten anderer Anbieter</h2>
          <p>
            Unser Online-Angebot enthält Links zu Webseiten anderer Anbieter.
            Wir haben keinen Einfluss darauf, dass diese Anbieter die
            Datenschutzbestimmungen einhalten.
          </p>

          <h2>Ihre Rechte</h2>
          <p>
            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
            Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und
            Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten
            gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
            Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich
            bei der Aufsichtsbehörde beschweren. In Österreich ist dies die
            Datenschutzbehörde.
          </p>
        </div>
      </div>
    );
  }
}

export default DataPrivacy;
