import { Component } from "react";
import { withRouter } from "react-router";

class Footer extends Component {
  onNavigate = (e, route) => {
    e.preventDefault();
    this.props.history.push(route);
  };

  render() {
    return (
      <div className="footer">
        <a
          href="/datenschutz"
          onClick={(e) => this.onNavigate(e, "/datenschutz")}
        >
          Datenschutz
        </a>
        <span>|</span>
        <a href="/impressum" onClick={(e) => this.onNavigate(e, "/impressum")}>
          Impressum
        </a>
      </div>
    );
  }
}

export default withRouter(Footer);
