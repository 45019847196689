import { Component } from "react";
import Banner from "./Banner";

class Imprint extends Component {
  render() {
    return (
      <div className="imprint">
        <Banner text={"Impressum"} />
        <div className="imprint-wrapper">
          <h4>
            Informationspflicht laut §5 E-Commerce Gesetz, §14
            Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
            laut §25 Mediengesetz.
          </h4>

          <h2>Seiteninhaber:</h2>
          <div>Dr. Sylvia Hackl</div>
          <div>Feschnigstraße 46</div>
          <div>9020, Klagenfurt am Wörthersee</div>
          <div>Österreich</div>

          <p>
            <div>
              E-Mail:{" "}
              <a href="mailto:praxis@derma-klagenfurt.at">
                praxis@derma-klagenfurt.at
              </a>
            </div>
          </p>

          <h2>Konzeption, Webdesign &amp; Webprogrammierung</h2>
          <div>
            Webdesign &amp; Development <i>anja.codes</i>
          </div>
          <div>Anja Ressmann</div>
          <div>
            <a href="https://anja.codes">https://anja.codes</a>
          </div>

          <h2>Bildernachweis</h2>
          <p>
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind
            urheberrechtlich geschützt.
          </p>
          <p>
            Die Bilderrechte liegen bei den folgenden Fotografen und
            Unternehmen:
          </p>
          <ul>
            <li>
              <a href="https://www.studiohorst.at/">
                Studio Horst haar &amp; foto
              </a>
            </li>
            <li>
              <a href="https://stock.adobe.come">Adobe Stock</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Imprint;
