import { Component } from "react";
import Banner from "./Banner";
import microscope from "../images/microscope.jpeg";
import operation_tools from "../images/child_dermatology.jpeg";
import allergy from "../images/allergy.jpeg";

class Services extends Component {
  render() {
    return (
      <div className="services">
        <Banner text={"Leistungen"} />
        <div className="services-wrapper">
          <ul className="services-list fade-in">
            <li>
              <h2>
                Allgemeine Dermatologie wie Akne, Schuppenflechte, Warzen,
                Pilzdiagnostik, Juckreizabklärung, Haar- und Nagelerkrankungen
                und vieles mehr
              </h2>
            </li>
            <li>
              <div>
                <h2>Allergieabklärung und Hyposensibilisierung</h2>
                <h2>Urtikaria (Nesselausschlag)</h2>
              </div>
            </li>
            <li>
              <h2>
                Versorgung von akuten und chronischen Wunden (nach Kammerlander)
              </h2>
            </li>
            <li>
              <div>
                <h2>Muttermalkontrolle</h2>
                <h2>Hautkrebsvorsorge</h2>
              </div>
            </li>
            <li>
              <h2>Kinderdermatologie</h2>
            </li>
            <li>
              <h2>Operative Dermatologie</h2>
            </li>
            <li>
              <h2>Chemische Peelings</h2>
            </li>
            <li>
              <h2>Tageslicht PDT</h2>
            </li>
          </ul>
          <div className="services-images">
            <img
              className="fade-in"
              src={microscope}
              alt="Symbolbild: Untersuchung mit dem Auflichtmikroskop"
            />
            <img className="fade-in-1" src={allergy} alt="Symbolbild: Allergie-Testung" />
            <img
              className="fade-in-2"
              src={operation_tools}
              alt="Symbolbild: ein Baby wird mit einer Salbe eingecremt"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
